body {
  margin: 0;
}

h1, h2, h3, p {
  color: #111;
  margin: 0;
}

h1 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 12px;
}

h2, h3, p {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-size: 15px;
  font-style: normal;
  line-height: 1.1;
}

h2 span {
  font-style: italic;
}

p {
  margin-bottom: 2px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1600px;
  padding: 24px;
  position: relative;
}

.details {
  width: 380px;
}

.fixed {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.pictures {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
}

.image-block {
  align-items: center;
  display: flex;
  flex-direction: column;
}

img {
  margin-bottom: 12px;
  width: 100%;
}

@media (min-width: 720px) {
  section {
    flex-direction: row;
  }

  .fixed {
    position: fixed;
  }

  .pictures {
    width: calc(100% - 380px);
  }
}